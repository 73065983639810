  <div class="container pt-4">
    <div class="row" style="display: flex; justify-content: space-between; margin-left: 0px; width: 100%;">
      <span class="title">{{'Products' | translate}}</span>
      <div class="action-buttons">
        <products-batch-upload class='margin'> </products-batch-upload>
        <div *ngIf="companyType === 'Standalone'">
          <generic-import (onImport)="getProducts(paginator.pageIndex, sortBy, sortDirection)" [data]="importDialogData"></generic-import>
        </div>
        <div>
          <button *ngIf="companyType === 'Standalone'" mat-flat-button color="primary" class="button-desc"
            routerLink="/manage-products" [queryParams]="{ mode: 'add' }">
            <mat-icon>add</mat-icon>{{'AddNEW'|translate }}
          </button>
        </div>
      </div>
    </div>
    <span class="sub-title">{{'ProductsDescription' | translate}}</span>
    <div class="table">
      <mat-tab-group animationDuration="0ms" mat-stretch-tabs="true" dynamicHeight (selectedTabChange)="changeTab($event.index)">
        <mat-tab label="{{'MyProducts' | translate}}">
          <ng-template matTabContent>
            <div class="body-header-table">
              <div class="pre-table-header" style="width: 100%; height: 40px;">
                <mat-form-field style="padding-right: 48px;">
                  <mat-chip-list #filterChipList>
                    <mat-chip *ngFor="let filter of filters" [selectable]="selectable" [removable]="true"
                      (removed)="removeFilter(filter)">
                      {{filter}}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="{{'SearchProduct'|translate}}" [matChipInputFor]="filterChipList"
                      [matChipInputSeparatorKeyCodes]="filterSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
                      (matChipInputTokenEnd)="addFilter($event)">
                  </mat-chip-list>
                </mat-form-field>
                <mat-icon (click)="openFilterOptions($event)" class="show-filters-btn">tune</mat-icon>
              </div>
            </div>
            <div class="main-square">
              <table mat-table [dataSource]="dataSource" matSort class="custom-table" (matSortChange)="sortData($event)">
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef class="centered">{{'Image'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <img style="height: 32px;" src="{{row.thumbnailUrl}}" alt="">
                  </td>
                </ng-container>
                <ng-container matColumnDef="productInfo">
                  <th mat-header-cell *matHeaderCellDef>{{'ProductInfo'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <div style="display: flex; flex-direction: column;">
                      <span>{{row.name}}</span>
                      <span class="productId-info">{{row.productId}}</span>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="ean">
                  <th mat-header-cell *matHeaderCellDef>{{'EAN'|translate}} </th>
                  <td mat-cell *matCellDef="let row">
                    {{row.ean}}
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="categories">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'ProductCategoriy'|translate}} </th>
                  <td mat-cell *matCellDef="let row"> {{row.category}} </td>
                </ng-container>

                <ng-container matColumnDef="brands">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'ProductBrand'|translate}} </th>
                  <td mat-cell *matCellDef="let row"> {{row.brand}} </td>
                </ng-container>

                <ng-container matColumnDef="qtUsedInPlanograms">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Planograms'|translate}} </th>
                    <td class="table-clickabel-item" 
                    mat-cell *matCellDef="let row" 
                    (click)="handleOpenProductPanogramList(row)" 
                      matTooltip="{{'ThisFieldShowsHowManyTimesThisProductHasBeenUsedInAnyPlanograms'|translate}}"
                    >
                      {{row.qtUsedInPlanograms}} 
                    </td>
                </ng-container>

                <ng-container matColumnDef="totalImageCatalogs">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Catalogs'|translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.totalImageCatalogs}}</td>
                </ng-container>

                <ng-container matColumnDef="totalImages">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Images'|translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.totalImages}} </td>
                </ng-container>

                <ng-container matColumnDef="updatedAt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'UpdatedAt'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <span *ngIf="row.updatedAt">{{formatDate(row.updatedAt)}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> {{'Status'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <span class="product-status" style="color: rgba(var(--primary-color-rgb), 1)" *ngIf="!row.inactive">{{'Active'|translate}}</span>
                    <span class="product-status" style="color: rgba(113, 113, 136, 1);" *ngIf="row.inactive">{{'Inactive'|translate}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="editIcon">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let row">
                    <a matTooltip="{{'Edit'|translate}}" mat-button routerLink="/manage-products"
                      [queryParams]="{ product_id : row.productId, mode: 'edit' }">
                      <mat-icon class="edit-icon-product">edit</mat-icon>
                    </a>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
              </table>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'Competitors' | translate}}">
          <ng-template matTabContent>
            <div class="body-header-table">
              <div class="pre-table-header" style="width: 100%; height: 40px;">
                <mat-form-field style="padding-right: 48px;">
                  <mat-chip-list #filterChipList>
                    <mat-chip *ngFor="let filter of filters" [selectable]="selectable" [removable]="true"
                      (removed)="removeFilter(filter)">
                      {{filter}}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="{{'SearchProduct'|translate}}" [matChipInputFor]="filterChipList"
                      [matChipInputSeparatorKeyCodes]="filterSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
                      (matChipInputTokenEnd)="addFilter($event)">
                  </mat-chip-list>
                </mat-form-field>
                <mat-icon (click)="openFilterOptions($event)" class="show-filters-btn">tune</mat-icon>
              </div>
            </div>
            <div class="main-square">
              <table mat-table [dataSource]="dataSource" matSort class="custom-table" (matSortChange)="sortData($event)">
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef class="centered">{{'Image'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <img style="height: 32px;" src="{{row.thumbnailUrl}}" alt="">
                  </td>
                </ng-container>
                <ng-container matColumnDef="productInfo">
                  <th mat-header-cell *matHeaderCellDef>{{'ProductInfo'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <div style="display: flex; flex-direction: column;">
                      <span>{{row.name}}</span>
                      <span class="productId-info">{{row.productId}}</span>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="ean">
                  <th mat-header-cell *matHeaderCellDef>{{'EAN'|translate}} </th>
                  <td mat-cell *matCellDef="let row">
                    {{row.ean}}
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="categories">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'ProductCategoriy'|translate}} </th>
                  <td mat-cell *matCellDef="let row"> {{row.category}} </td>
                </ng-container>

                <ng-container matColumnDef="brands">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'ProductBrand'|translate}} </th>
                  <td mat-cell *matCellDef="let row"> {{row.brand}} </td>
                </ng-container>

                <ng-container matColumnDef="qtUsedInPlanograms">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Planograms'|translate}} </th>
                    <td class="table-clickabel-item" 
                    mat-cell *matCellDef="let row" 
                    (click)="handleOpenProductPanogramList(row)" 
                      matTooltip="{{'ThisFieldShowsHowManyTimesThisProductHasBeenUsedInAnyPlanograms'|translate}}"
                    >
                      {{row.qtUsedInPlanograms}} 
                    </td>
                </ng-container>

                <ng-container matColumnDef="totalImageCatalogs">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Catalogs'|translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.totalImageCatalogs}}</td>
                </ng-container>

                <ng-container matColumnDef="totalImages">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Images'|translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.totalImages}} </td>
                </ng-container>

                <ng-container matColumnDef="updatedAt">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'LastUpdate'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <span *ngIf="row.updatedAt">{{formatDate(row.updatedAt)}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> {{'Status'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <span class="product-status" style="color: rgba(var(--primary-color-rgb), 1)" *ngIf="!row.inactive">{{'Active'|translate}}</span>
                    <span class="product-status" style="color: rgba(113, 113, 136, 1);" *ngIf="row.inactive">{{'Inactive'|translate}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="editIcon">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let row">
                    <a matTooltip="{{'Edit'|translate}}" mat-button routerLink="/manage-products"
                      [queryParams]="{ product_id : row.productId, mode: 'edit' }">
                      <mat-icon class="edit-icon-product">edit</mat-icon>
                    </a>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
              </table>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'Others' | translate}}">
          <ng-template matTabContent>
            <div class="body-header-table">
              <div class="pre-table-header" style="width: 100%; height: 40px;">
                <mat-form-field style="padding-right: 48px;">
                  <mat-chip-list #filterChipList>
                    <mat-chip *ngFor="let filter of filters" [selectable]="selectable" [removable]="true"
                      (removed)="removeFilter(filter)">
                      {{filter}}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="{{'SearchProduct'|translate}}" [matChipInputFor]="filterChipList"
                      [matChipInputSeparatorKeyCodes]="filterSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
                      (matChipInputTokenEnd)="addFilter($event)">
                  </mat-chip-list>
                </mat-form-field>
                <mat-icon (click)="openFilterOptions($event)" class="show-filters-btn">tune</mat-icon>
              </div>
            </div>
            <div class="main-square">
              <table mat-table [dataSource]="dataSource" matSort class="custom-table" (matSortChange)="sortData($event)">
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef class="centered">{{'Image'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <img style="height: 32px;" src="{{row.thumbnailUrl}}" alt="">
                  </td>
                </ng-container>
                <ng-container matColumnDef="productInfo">
                  <th mat-header-cell *matHeaderCellDef>{{'ProductInfo'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <div style="display: flex; flex-direction: column;">
                      <span>{{row.name}}</span>
                      <span class="productId-info">{{row.productId}}</span>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="qtUsedInPlanograms">
                    <th mat-header-cell *matHeaderCellDef> {{'Planograms'|translate}} </th>
                    <td class="table-clickabel-item" 
                    mat-cell *matCellDef="let row" 
                    (click)="handleOpenProductPanogramList(row)" 
                      matTooltip="{{'ThisFieldShowsHowManyTimesThisProductHasBeenUsedInAnyPlanograms'|translate}}"
                    >
                      {{row.qtUsedInPlanograms}} 
                    </td>
                </ng-container>

                <ng-container matColumnDef="totalImageCatalogs">
                  <th mat-header-cell *matHeaderCellDef>{{'Catalogs'|translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.totalImageCatalogs}}</td>
                </ng-container>

                <ng-container matColumnDef="totalImages">
                  <th mat-header-cell *matHeaderCellDef>{{'Images'|translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.totalImages}} </td>
                </ng-container>

                <ng-container matColumnDef="updatedAt">
                  <th mat-header-cell *matHeaderCellDef> {{'LastUpdate'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <span *ngIf="row.updatedAt">{{formatDate(row.updatedAt)}}</span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="editIcon">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let row">
                    <a matTooltip="{{'Edit'|translate}}" mat-button routerLink="/manage-products"
                      [queryParams]="{ product_id : row.productId, mode: 'edit' }">
                      <mat-icon class="edit-icon-product">edit</mat-icon>
                    </a>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsOthers"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsOthers;">
                </tr>
              </table>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <div style="display: flex; place-content: space-between;">
        <button mat-stroked-button class="button-export" (click)="exportProducts()">{{'Export' | translate}} <mat-icon>arrow_downward</mat-icon></button>
        <mat-paginator style="border-radius: 10px;" [length]="totalProducts" [pageSizeOptions]="[5, 10, 25, 100]" (page)="applyFilter()">
        </mat-paginator>
      </div>
    </div>
  </div>
