<div class="main">
  <div class="content">
    <div class="flex-3 images_list">
      <div class="planogram_details">
        <div class="inner">
          <div class="header">{{'Planogram'|translate|uppercase}}</div>
          <div class="name">{{data?.planogramName}}</div>
          <div class="code">{{data?.planogramId}}</div>
          <div class="type">{{'Type' | translate}}: {{data?.planogramType}}</div>
          <div *ngIf="data?.editedBy" class="type">{{'EditedBy' | translate}}: {{data?.editedBy | uppercase}}</div>
        </div>
      </div>
      <div class="images_grid_header">{{'ImagesList' | translate | uppercase }}</div>
      <div class="images_grid">
        <div *ngFor="let image of data?.images" class="image_list_wrap" (click)="onImageSelected(image)"
          [ngClass]="{selected: image.imagePath === selectedImage.imagePath }">
          <img crossorigin="anonymous" [src]="image.imagePath" [id]="image.imagePath" />
        </div>
      </div>
    </div>
    <div class="flex-7 image_container">
      <image-buttons [mode]="imageMode" (modeChange)="changeMode($event)" (reset)="reset()"></image-buttons>
      <accuracy-bar [accuracy]="accuracy"></accuracy-bar>
      <div class="image_wrap">
        <div class="image_wrap_inner" #imageWrapInner>
          <main-image [markers]="markers" [image]="selectedImage" (onMarkSelected)="markSelected($event)"
            (markChange)='changeResult($event)' (onAnnotationDrawn)="addResult($event)" *ngIf="selectedImage"
            [maxHeight]="imageHeight" [height]="imageHeight"  [top]="imageTop" [left]="imageLeft" [maxWidth]="imageWidth" [mode]="imageMode"
            [tagSelected]="selectedProduct?.product.key" [selectedMarker]="selectedAnnotation?.id"
            [imageRotation]="imageRotation"></main-image>
          <div [ngStyle]="{display: dragMode ? 'block' : 'none'}" class="dragging_image" #dragger></div>
          <button (click)="resetImagePosition()" mat-fab color="primary" class="reset_zoom"
            aria-label="Reset image position">
            <mat-icon>
              center_focus_strong
            </mat-icon>
          </button>
          <button (click)="rotateImage()" mat-fab color="primary" class="rotate_image" aria-label="Rotate">
            <mat-icon>
              rotate_left
            </mat-icon>
          </button>
        </div>
        <mat-icon (click)="moveImage('left')" [ngClass]="{disabled: !findNearImage('left') }"
          class="image_slide_button slide_left">
          arrow_back
        </mat-icon>
        <mat-icon (click)="moveImage('right')" [ngClass]="{disabled: !findNearImage('right') }"
          class="image_slide_button slide_right">
          arrow_forward
        </mat-icon>
        <mat-icon (click)="moveImage('top')" [ngClass]="{disabled: !findNearImage('top') }"
          class="image_slide_button slide_top">
          arrow_upward
        </mat-icon>
        <mat-icon (click)="moveImage('bottom')" [ngClass]="{disabled: !findNearImage( 'bottom') }"
          class="image_slide_button slide_bottom">
          arrow_downward
        </mat-icon>
      </div>
      <div>
        <div class="save_button_wrap">
          <button [disabled]="saving" (click)="save()" mat-flat-button class="save_button active flex-1">
            <div class="inner_icon_txt_btn"><mat-icon>save</mat-icon>{{'SaveChanges' | translate}}</div>
          </button>
        </div>
        <div class="send_to_unapproved_wrap">
          <button [disabled]="!selectedAnnotation" mat-flat-button
            (click)="sendAnnotationToUnapproved()">{{'SendToUnapproved' | translate}}</button>
        </div>
        <!-- <div>
          <mat-checkbox></mat-checkbox>
        </div> -->
        <images-map class="images-map" (select)="onImageSelected($event)" [selectedImage]="selectedImage"
          [imageLocations]="data?.images"></images-map>
      </div>
    </div>
    <div class="flex-3">
      <div class="products_list">
        <div class="header">
          {{"ProductsList" | translate}}
        </div>
        <div class="border_line"></div>
        <mat-tab-group animationDuration="10ms" (selectedTabChange)="tabChanged($event)">
          <mat-tab>
            <ng-template mat-tab-label>
              <div class="tabs_label">{{ 'AnnotatedProducts' | translate }}</div>
            </ng-template>
            <mat-form-field class="example-full-width">
              <mat-label>{{'SearchForProduct' | translate}}</mat-label>
              <input matInput [placeholder]="'NameCode'| translate" [(ngModel)]="annotadedFilter">
            </mat-form-field>
            <div class="products_list_wrap">
              <list-product id="ap_{{product.product.key}}"
                *ngFor="let product of extendedProducts | callbackFilter:annotatedProductFilterCallback:selectedImagePath | callbackFilter:productFilterCallback:annotadedFilter"
                [prices]="data.priceReports" [extendedProduct]="product" (click)="productSelected(product)"
                (onToggleVisibility)="toggleVisibility($event)"
                [selected]="selectedProduct?.product.key === product.product.key"
                [annotationSelected]="selectedAnnotation?.tag === product.product.key"></list-product>
            </div>
          </mat-tab>
          <mat-tab label="{{ 'AllProducts' | translate }}">
            <ng-template mat-tab-label>
              <div class="tabs_label">{{ 'AllProducts' | translate }} </div>
            </ng-template>
            <mat-form-field class="example-full-width">
              <mat-label>{{'SearchForProduct' | translate}}</mat-label>
              <input matInput [placeholder]=" 'NameCode' | translate" (input)="onFilterAllProducts($event)">
            </mat-form-field>
            <div class="products_list_wrap">
              <list-product *ngFor="let product of allProducts" [prices]="data.priceReports" [extendedProduct]="product"
                [selected]="selectedProduct?.product.key === product.product.key" [hideVisibility]="true"
                [annotationSelected]="selectedAnnotation?.tag === product.product.key"
                (click)="productSelected(product)" id="allp_{{product.product.key}}"></list-product>
              <mat-paginator (page)="fetchAllProducts()"></mat-paginator>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>