<div class="container">
  <div class="container-tabs">
    <mat-tab-group #tabGroup animationDuration="0ms" mat-stretch-tabs="true" dynamicHeight
      (selectedTabChange)="changeTab($event.index)">
      <mat-tab label="{{'ProductDetails' | translate}}">
        <form [formGroup]="productForm" ngNativeValidate>
          <div class="container-product-info">
            <div class="product">
              <div class="title">
                <h6>{{'ProductInformation' | translate}}</h6>
              </div>
              <div class="image" *ngIf="enableScreen">
                <img src="{{thumbnailUrl}}">
              </div>
              <div class="description" *ngIf="enableScreen">
                <div class="descrition-info">
                  {{'ProductId' | translate | uppercase}}
                  <mat-icon [title]="'ProductIDHelpText' | translate">help</mat-icon>
                </div>
                
                <br>
                <span class="description-names">{{productId}}</span>
              </div>
            </div>

            <div class="product-information">
              <div class="title">
                <h6>{{'ProductSpec' | translate}}</h6>
                <div>
                  <mat-slide-toggle class="toggle-font mx-2" [labelPosition]="'before'" *ngIf="enableScreen"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="_inactive" formControlName="inactive">{{'Inactive'
                    | translate}}</mat-slide-toggle>
                  <mat-slide-toggle class="toggle-font" [labelPosition]="'before'" *ngIf="enableScreen"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="_isCompetitorsCtrl" formControlName="isCompetitorsCtrl">{{'Competitors'
                  | translate}}</mat-slide-toggle>
                </div>
              </div>
              <div class="general" *ngIf="enableScreen">
                <span class="general-desc">{{'General' | translate}}</span>
                <div class="first-row">
                  <mat-form-field>
                    <input matInput type="string" placeholder="{{'Name' | translate}}" formControlName="nameCtrl"
                      required>
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput type="string" placeholder="{{'ExternalId' | translate}}" formControlName="externalIdCtrl">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput type="string" placeholder="{{'EAN' | translate}}" formControlName="eanCtrl">
                  </mat-form-field>

              
                </div>
                <div class="second-row" *ngIf="enableScreen">
                  <mat-form-field>
                    <input matInput placeholder="{{'ProductCategoriy'|translate}}" [matAutocomplete]="auto"
                      formControlName="categoryCtrl" #exposure (click)="dropDownCategoryOptions()" required>
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                      (optionSelected)="categoryOptionSelected($event.option)" [displayWith]="displayCategory">
                      <mat-option *ngFor="let category of filteredCategoryItems | async" [value]="category">
                        {{category.name}}</mat-option>
                    </mat-autocomplete>
                    <button *ngIf="showAddCategoryButton && exposure.value" matSuffix mat-button mat-icon-button
                      (click)="isShowAddCategoryDialog=true"><mat-icon
                        matTooltip='Add {{exposure.value}}'>add</mat-icon></button>
                    <mat-icon matSuffix *ngIf="isLoadingCategories">
                      <i class="fa fa-spinner fa-spin"></i>
                    </mat-icon>
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput placeholder="{{'Brand'|translate}}" [matAutocomplete]="auto2"
                      formControlName="brandCtrl" #exposure2 (click)="dropDownBrandOptions()" required>
                    <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete"
                      (optionSelected)="brandOptionSelected($event.option)" [displayWith]="displayBrand">
                      <mat-option *ngFor="let brand of filteredBrandItems | async" [value]="brand">
                        {{brand.name}}</mat-option>
                    </mat-autocomplete>
                    <button *ngIf="showAddBrandButton && exposure2.value" matSuffix mat-button mat-icon-button
                      (click)="isShowAddBrandDialog=true"><mat-icon
                        matTooltip='Add {{exposure2.value}}'>add</mat-icon></button>
                    <mat-icon matSuffix *ngIf="isLoadingBrands">
                      <i class="fa fa-spinner fa-spin"></i>
                    </mat-icon>
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput placeholder="{{'ProductSimilarity'|translate}}" [matAutocomplete]="auto3"
                      formControlName="similarityGroupCtrl" #exposure3 (click)="dropDownSimilarityGroupOptions()">
                    <mat-autocomplete autoActiveFirstOption #auto3="matAutocomplete"
                      (optionSelected)="similarityGroupOptionSelected($event.option)" [displayWith]="displaySimilarityGroup">
                      <mat-option *ngFor="let similarityGroup of filteredSimilarityGroupItems | async" [value]="similarityGroup">
                        {{similarityGroup.name}}</mat-option>
                    </mat-autocomplete>
                    <button *ngIf="showAddSimilarityGroupButton && exposure3.value" matSuffix mat-button mat-icon-button
                      (click)="isShowAddSimilarityGroupDialog=true"><mat-icon
                        matTooltip='Add {{exposure3.value}}'>add</mat-icon></button>
                    <mat-icon matSuffix *ngIf="isLoadingSimilarityGroups">
                      <i class="fa fa-spinner fa-spin"></i>
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="second-row" *ngIf="enableScreen && companyType !== 'MC1'">
                  <mat-form-field>
                    <input matInput type="number" formControlName="widthCtrl" required
                      placeholder="{{'Width' | translate}} ({{'CentimeterAb' | translate}})">
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput type="number" formControlName="heightCtrl" required
                      placeholder="{{'Height' | translate}} ({{'CentimeterAb' | translate}})">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="{{'ProductCatalogs' | translate}}" [disabled]="mode !== 'edit'">
        <div class="container-catalogs">
          <div class="catalogs">
            <div *ngIf="enableScreen" class="title" style="padding: 0px 12px;">
              <h6>{{imagesCatalogs.length}} {{'Catalogs' | translate}}</h6>
              <button *ngIf="productId !== 'noise' || productId !== 'invasor'" mat-flat-button
                matTooltip="{{ 'NewCatalog' | translate | uppercase }}" class="blue-button" (click)="createCatalog()">
                <mat-icon>library_add</mat-icon> {{'NewCatalog'|translate|uppercase }}
              </button>
            </div>
            <div class="catalog catalog-selected new-catalog" *ngIf="isNewCatalog">
              <div class="image">
                <img class="img-catalog-thumb" [src]="images[0]?.thumbnailPath ?? ''">
              </div>
              <div class="info">
                <div style="display: flex; place-content: space-between;">
                  <span class="catalog-name">{{'Catalog'|translate }}</span>
                </div>
                <span class="catalog-title">{{catalogForm.get('catalogName')?.value}}</span>
                <div style="display: flex; gap: 20%;">
                  <span *ngIf="showCatalogDimensions" class="size-description">{{'Width' | translate}}:
                    {{catalogForm.get('catalogWidth')?.value}}</span>
                  <span *ngIf="showCatalogDimensions" class="size-description">{{'Height' |
                    translate}}: {{catalogForm.get('catalogHeight')?.value}}</span>
                </div>
              </div>
            </div>
            <div [ngClass]="catalog.id === catalogSelected ? 'catalog-selected' : ''"
              *ngFor="let catalog of imagesCatalogs" class="catalog" (click)="selectCatalog(catalog)">
              <div class="image">
                <img class="img-catalog-thumb" src="{{catalog.thumbnailUrl}}">
              </div>
              <div class="info">
                <div style="display: flex; place-content: space-between;">
                  <span class="catalog-name">{{'Catalog'|translate }}</span>
                  <mat-icon *ngIf="catalog.isPrimary && (productId !== 'noise' || productId !== 'invasor')"
                    style="color: rgba(242, 183, 32, 1);">star</mat-icon>
                  <mat-icon *ngIf="!catalog.isPrimary && (productId !== 'noise' || productId !== 'invasor')"
                    class="favorite-catalog" (click)="favoriteCatalog(catalog.id, $event)">star_border</mat-icon>
                </div>
                <span class="catalog-title">{{catalog.name}}</span>
                <div style="display: flex; gap: 20%;">
                  <span *ngIf="catalog.width && showCatalogDimensions" class="size-description">{{'Width' | translate}}:
                    {{catalog.width}}</span>
                  <span *ngIf="catalog.height && showCatalogDimensions" class="size-description">{{'Height' |
                    translate}}: {{catalog.height}}</span>
                </div>
                <div *ngIf="(productId !== 'noise' || productId !== 'invasor')" style="text-align-last: right;">
                  <button mat-flat-button matTooltip="{{'RemoveCatalog' | translate }}" class="red-button"
                    (click)="removeCatalog(catalog, $event)">
                    <mat-icon>delete</mat-icon> {{'RemoveCatalog'|translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="catalogSelected || isNewCatalog" class="details-images">
            <div class="catalog-info">
              <div class="catalog-info-header" *ngIf="enableScreen && isNewCatalog">
                <h6 style="margin-bottom: 16px;">{{'NewCatalog' | translate}}</h6>
              </div>
              <div class="catalog-info-header" *ngIf="enableScreen && !isNewCatalog">
                <h6 style="margin-bottom: 16px;">{{'CatalogInformation' | translate}}</h6>
                <button *ngIf="shouldShowRemoveCatalog" mat-flat-button matTooltip="{{'RemoveCatalog' | translate }}"
                  class="red-button" (click)="removeCatalog()">
                  <mat-icon>delete</mat-icon> {{'RemoveCatalog'|translate }}
                </button>
              </div>
              <form [formGroup]="catalogForm" ngNativeValidate>
                <div>
                  <mat-form-field>
                    <input matInput style="color: #919191;" type="string" placeholder="{{'CatalogName' | translate}}"
                      required formControlName="catalogName">
                  </mat-form-field>
                  <div *ngIf="showCatalogDimensions" class="second-row">
                    <mat-form-field>
                      <input matInput type="string"
                        placeholder="{{'Width' | translate}} ({{'CentimeterAb' | translate}})"
                        formControlName="catalogWidth">
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput type="string"
                        placeholder="{{'Height' | translate}} ({{'CentimeterAb' | translate}})"
                        formControlName="catalogHeight">
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
            <div class="catalog-images">
              <div class="title" style="padding: 0px 12px;">
                <div *ngIf="!isNewCatalog" style="display: flex; gap: 8px; margin-right: 16px;">
                  <h6 style="
                  font-size: 20px;
                  font-weight: 500;
                  ">{{'CatalogImages' | translate}}</h6>
                  <h6 style="
                  font-size: 14px;
                  font-weight: 400;
                  color: #6B6B6B;
                ">{{'TotalImages' | translate}}: {{totalImages}}</h6>
                </div>
                <div *ngIf="!isNewCatalog" style="display: flex; align-items: end; gap: 16px">
                  <button class="button-stroked" mat-stroked-button
                    matTooltip="{{ 'DownloadAllImages' | translate | uppercase }}" (click)="downloadAllImages()">
                    {{'DownloadAllImages'|translate}}
                  </button>
                  <input type="file" accept=".gif, .jpg, .png" id="multipleFiles" (change)="uploadImages($event)"
                    multiple="multiple" [hidden]="true">

                  <button class="button-normal" matTooltip="{{'UPloadIMAGES'|translate}}"
                    (click)="uploadImageBtnClick()" mat-button>
                    {{'UPloadIMAGES'|translate}}
                  </button>
                </div>
                <div *ngIf="isNewCatalog" style="display: flex; align-items: end; gap: 6px">
                  <input type="file" accept=".gif, .jpg, .png" id="singleFile" (change)="uploadPrimaryImage($event)"
                    [hidden]="true">
                  <button *ngIf="images.length === 0" class="button-normal" matTooltip="{{'UploadMainImage'|translate}}"
                    (click)="uploadPrimaryImageBtnClick()" mat-button>
                    {{'UploadMainImage'|translate}}
                  </button>
                </div>
              </div>
              <ul class="images-list">
                <li *ngFor="let image of images let i=index">
                  <mat-card class="mat-elevation-z0 img-card image-block">
                    <button mat-flat-button color="primary" class="custom-tooltip"
                    (click)="openImage(image.thumbnailPath)">{{'ViewImage'|translate}}</button>
                    <div class="image-control">
                      <mat-icon *ngIf="!image.isPrimary" style="color: rgba(214, 64, 64, 1); cursor: pointer;"
                        (click)="deleteImageFromCatalog(image.fileLocation, $event)">delete</mat-icon>
                      <mat-icon *ngIf="image.isPrimary && productId !=='noise'"
                        style="color: rgba(242, 183, 32, 1);">star</mat-icon>
                      <mat-icon *ngIf="!image.isPrimary && productId !=='noise'" style="color: rgba(51, 100, 162, 1); cursor: pointer;"
                        (click)="favoriteImage(image, $event)">star_border</mat-icon>
                      <mat-icon style="color: rgba(var(--primary-color-rgb), 1); cursor: pointer;"
                        (click)="downloadImageFromCatalog(image.thumbnailPath, $event)">get_app</mat-icon>
                    </div>
                    <div class="image">
                      <img class="img-list" ngSrc="{{image.thumbnailPath}}" width="100" height="100" loading="lazy">
                    </div>
                    <div style="font-size: 11.3px; padding: 4px;">
                      <div *ngIf="image.lastUpdate" style="display: flex; place-content: space-between;">
                        <span>{{'Date'|translate}}</span>
                        <span>{{image.lastUpdate | date : 'dd/MM/yyyy HH:mm' }}</span>
                      </div>
                      <div *ngIf="image.approvalName" style="display: flex; place-content: space-between;">
                        <span>{{'User'|translate}}</span>
                        <span>{{image.approvalName }}</span>
                      </div>
                    </div>
                  </mat-card>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<div class="action-buttons-wrapper">
  <div class="action-buttons">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <button class="button-stroked" mat-stroked-button routerLink="/products">
        <mat-icon>arrow_back</mat-icon>
        {{'Back'|translate}}
      </button>
      <button class="button-normal" (click)="save()"
        [disabled]="(this.tabGroup.selectedIndex === 0 && companyType === 'MC1') || productId === 'noise' || productId === 'invasor'"
        mat-button>
        {{'Save&Continue'|translate}}
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </div>
</div>
<p-dialog [(visible)]="isShowAddCategoryDialog">
  <p-header>{{ 'Warning' | translate }}</p-header>
  <p>{{'AddCategoryCaution'|translate}}</p>
  <div class="dialog-button-container">
    <button mat-flat-button color="primary" (click)="isShowAddCategoryDialog=false">{{'Cancel' |translate}}</button>
    <button mat-flat-button (click)="addNewCategory()">{{'AddCategory' | translate}}</button>
  </div>
</p-dialog>
<p-dialog [(visible)]="isShowAddBrandDialog">
  <p-header>{{ 'Warning' | translate }}</p-header>
  <p>{{'AddBrandCaution'|translate}}</p>
  <div class="dialog-button-container">
    <button mat-flat-button color="primary" (click)="isShowAddBrandDialog=false">{{'Cancel' |translate}}</button>
    <button mat-flat-button (click)="addNewBrand()">{{'ADD' | translate}}</button>
  </div>
</p-dialog>
<p-dialog [(visible)]="isShowAddSimilarityGroupDialog">
  <p-header>{{ 'Warning' | translate }}</p-header>
  <p>{{'AddSimilarityGroupCaution'|translate}}</p>
  <div class="dialog-button-container">
    <button mat-flat-button color="primary" (click)="isShowAddSimilarityGroupDialog=false">{{'Cancel' |translate}}</button>
    <button mat-flat-button (click)="addNewSimilarityGroup()">{{'ADD' | translate}}</button>
  </div>
</p-dialog>
